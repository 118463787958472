import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserStorageService } from '@features/login/user-storage.service';

export const authenticatedInterceptor: HttpInterceptorFn = (req, next) => {
    const router = inject(Router);
    const userStorageService = inject(UserStorageService);

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison */
            if (error.status === HttpStatusCode.Unauthorized) {
                userStorageService.removeUser();
                void router.navigate(['/login']);
                return throwError(() => new Error(`Votre session n'est pas active`));
            }
            return throwError(() => error);
        }),
    );
};
