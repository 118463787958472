import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { isValid, parse } from 'date-fns';

export class MaterialCustomDateAdapter extends DateFnsAdapter {
    override parse(value: unknown, parseFormat: string | string[]): Date | null {
        if (typeof value !== 'string' || !value) {
            return null;
        }

        if (Array.isArray(parseFormat)) {
            for (const format of parseFormat) {
                const parsedDate = this.#tryParse(value, format);
                if (parsedDate) return parsedDate;
            }
            return new Date('Invalid Date');
        } else {
            return this.#tryParse(value, parseFormat);
        }
    }

    #tryParse(value: string, format: string): Date | null {
        const parsedDate = parse(value, format, new Date());
        if (isValid(parsedDate)) {
            return parsedDate;
        }
        return new Date('Invalid Date');
    }
}
