import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeFrCHExtra from '@angular/common/locales/extra/fr-CH';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { frCH } from 'date-fns/locale';
import { MaterialCustomDateAdapter } from '@shared/angular-material/material-custom-date-adapter';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import * as Sentry from '@sentry/angular';
import { loadingInterceptor } from '@shared/interceptors/loading.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MaterialCustomPaginatorIntl } from '@shared/angular-material/material-custom-paginator-intl';
import { authenticatedInterceptor } from '@shared/interceptors/authenticated.interceptor';
import { sidHeaderInterceptor } from '@shared/interceptors/sid-header.interceptor';
import { RuntimeConfigService } from '@shared/services/runtime-config.service';

registerLocaleData(localeFrCH, 'fr-CH', localeFrCHExtra);
function initializeAppRuntimeConfig(configService: RuntimeConfigService): () => Promise<void> {
    return () => configService.loadConfig();
}

export const appConfig: ApplicationConfig = {
    providers: [
        // Experimental support for zoneless change detection
        provideExperimentalZonelessChangeDetection(),
        // provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withComponentInputBinding()),
        provideAnimationsAsync(),
        { provide: MatPaginatorIntl, useClass: MaterialCustomPaginatorIntl },
        { provide: LOCALE_ID, useValue: 'fr-CH' },
        { provide: MAT_DATE_LOCALE, useValue: frCH },
        provideDateFnsAdapter({
            ...MAT_DATE_FNS_FORMATS,
            parse: {
                dateInput: ['dd.MM.yyyy'],
            },
        }),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                subscriptSizing: 'dynamic',
            },
        },
        { provide: DateAdapter, useClass: MaterialCustomDateAdapter },
        provideHttpClient(withInterceptors([loadingInterceptor, authenticatedInterceptor, sidHeaderInterceptor])),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            /* eslint-disable-next-line @typescript-eslint/no-empty-function */
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppRuntimeConfig,
            deps: [RuntimeConfigService],
            multi: true,
        },
    ],
};
