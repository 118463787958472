import { Routes } from '@angular/router';
import HomePageComponent from '@features/home/home-page.component';
import { tripByIdResolver } from '@features/trip/trip-by-id.resolver';
import { authGuard } from '@features/login/auth.guard';
import { loggedInGuard } from '@features/login/logged-in.guard';
import { parametersGuard } from '@features/parameters/parameters.guard';

export const routes: Routes = [
    {
        path: 'login',
        canActivate: [loggedInGuard],
        loadComponent: () => import('@features/login/login-page.component').then((m) => m.LoginPageComponent),
    },
    {
        path: 'home',
        component: HomePageComponent,
        canActivate: [authGuard],
        children: [
            {
                path: 'trips',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('@features/trip/list/trip-list-page.component'),
                    },
                    {
                        path: ':id',
                        resolve: {
                            tripFromResolver: tripByIdResolver,
                        },
                        loadComponent: () => import('@features/trip/details/trip-details-page.component'),
                    },
                    { path: '**', redirectTo: '' },
                ],
            },
            {
                path: 'parameters',
                canActivate: [parametersGuard],
                loadComponent: () =>
                    import('@features/parameters/parameters-page.component').then((component) => component.ParametersPageComponent),
            },
            { path: '**', redirectTo: 'trips' },
        ],
    },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login' },
];
