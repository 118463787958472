/// <reference types="@angular/localize" />

import { createApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { RuntimeConfigService } from '@shared/services/runtime-config.service';
import { environment } from '@environment';
import { versionEnv } from '@version';
import { ComponentRef, enableProdMode } from '@angular/core';

if (environment.production) {
    enableProdMode();
}

async function initializeApp(): Promise<ComponentRef<AppComponent>> {
    // Crée l'application et attend que les APP_INITIALIZER soient exécutés
    const appRef = await createApplication(appConfig);

    // Récupère le service de runtimeAppConfig
    const runtimeConfigService = appRef.injector.get(RuntimeConfigService);
    const runtimeAppConfig = runtimeConfigService.config();

    // Vérifie que la runtimeAppConfig est bien chargée
    if (!runtimeAppConfig) {
        throw new Error('Runtime configuration not loaded');
    }

    // Initialize Sentry si activé
    if (runtimeAppConfig.enableSentry) {
        Sentry.init({
            dsn: 'https://624fa819dec5c97e5e3cbfd5e5b70b77@o311737.ingest.us.sentry.io/4508221592305664',
            release: `sae@${versionEnv.version}`,
            dist: versionEnv.commitHash,
            environment: runtimeAppConfig.sentryEnvironmentName,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', runtimeAppConfig.backendUrl],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            enabled: runtimeAppConfig.enableSentry,
            debug: false, // Rend Sentry très verbeux avec pleins de console.log sur ce qui se passe
            beforeBreadcrumb(breadcrumb) {
                if (breadcrumb.level === 'debug') return null;
                return breadcrumb;
            },
        });
    }
    console.log('Application initialized', runtimeAppConfig);

    return appRef.bootstrap(AppComponent);
}

initializeApp().catch((err) => console.error(`Error initializing application: ${err}`));
