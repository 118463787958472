import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { JsonPipe } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LoadingService } from '@shared/services/loading.service';
import { UserMenuComponent } from '@features/home/user-menu.component';
import { NavbarComponent } from './navbar.component';
import { KeepAliveService } from '@shared/services/keep-alive.service';
import { ParametersService } from '../parameters/parameters.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-home-page',
    standalone: true,
    imports: [
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        RouterLink,
        RouterLinkActive,
        RouterOutlet,
        JsonPipe,
        MatDialogContent,
        MatDialogTitle,
        MatProgressBar,
        UserMenuComponent,
        NavbarComponent,
    ],
    template: `
        <div class="absolute top-0 left-0 w-full z-50">
            @if (loadingService.loading()) {
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }
        </div>
        <div class="flex flex-col h-full">
            <app-navbar />
            <mat-tab-nav-panel #tabPanel class="overflow-hidden flex-1">
                <div class="container mx-auto h-full py-8">
                    <router-outlet></router-outlet>
                </div>
            </mat-tab-nav-panel>
        </div>
    `,
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class HomePageComponent {
    #keepAliveService = inject(KeepAliveService);
    #parametersService = inject(ParametersService);
    #snackBar = inject(MatSnackBar);
    loadingService = inject(LoadingService);

    constructor() {
        this.#keepAliveService.startPolling();
        try {
            void this.#parametersService.updateParametersFromApi();
        } catch {
            this.#snackBar.open(`Erreur lors du chargement des paramètres d'actions de régulation.`, 'Fermer', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
            });
        }
    }
}
