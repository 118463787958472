import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const loggedInGuard: CanActivateFn = () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    if (authService.isAuthenticated()) {
        // Si l'utilisateur est connecté, redirigez-le vers la page d'accueil
        return router.createUrlTree(['/home']);
    }

    // Si l'utilisateur n'est pas connecté, autorisez l'accès à la page de connexion
    return true;
};
