import { Subject } from 'rxjs';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class MaterialCustomPaginatorIntl implements MatPaginatorIntl {
    changes = new Subject<void>();

    // For internationalization, the `$localize` function from
    // the `@angular/localize` package can be used.
    firstPageLabel = $localize`Premier page`;
    itemsPerPageLabel = $localize`Éléments par page:`;
    lastPageLabel = $localize`Dernière page`;

    // You can set labels to an arbitrary string too, or dynamically compute
    // it through other third-party internationalization libraries.
    nextPageLabel = 'Page suivante';
    previousPageLabel = 'Page précédente';

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return $localize`Page 1 sur 1 - 0 éléments`;
        }
        const amountPages = Math.ceil(length / pageSize);
        return $localize`Page ${page + 1} sur ${amountPages} - ${length} éléments`;
    }
}
