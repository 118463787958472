import { ChangeDetectionStrategy, Component, computed, inject, signal, TemplateRef, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UserMenuComponent } from './user-menu.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FeatureSettings } from '@features-settings';
import { environment } from '@environment';
import { versionEnv } from '@version';
import { MatDialog, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { JsonPipe } from '@angular/common';
import { AuthService } from '../login/auth.service';
import { RuntimeConfigService } from '@shared/services/runtime-config.service';

interface MenuItem {
    path: string;
    label: string;
    iconName: string;
}

@Component({
    selector: 'app-navbar',
    standalone: true,
    imports: [MatButtonModule, UserMenuComponent, RouterModule, MatIconModule, JsonPipe, MatDialogContent, MatDialogTitle],
    template: `
        <nav class="border-outline-variant border-b-[1px] bg-surface">
            <div class="container mx-auto flex flex-wrap items-center justify-between py-4">
                <a [routerLink]="['/home']">
                    <img
                        src="assets/logo.svg"
                        class="h-8"
                        (mousedown)="startPress($event)"
                        (touchstart)="startPress($event)"
                        (mouseup)="cancelPress()"
                        (mouseleave)="cancelPress()"
                        (touchend)="cancelPress()"
                        (touchcancel)="cancelPress()"
                        alt="logo"
                    />
                </a>
                <div class="block w-auto">
                    <ul class="flex flex-row gap-4">
                        @for (link of links(); track link.path; let last = $last) {
                            <li>
                                <a mat-button [routerLink]="link.path" routerLinkActive="active">
                                    <mat-icon fontSet="material-icons-outlined">{{ link.iconName }}</mat-icon>
                                    {{ link.label }}
                                </a>
                            </li>
                        }
                        <li>
                            <app-user-menu />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <ng-template #debugDialog>
            <div mat-dialog-title>Informations de debug</div>
            <mat-dialog-content>
                <pre>{{ debugInfo() | json }}</pre>
            </mat-dialog-content>
        </ng-template>
    `,
    styles: `
        .active {
            position: relative;
            z-index: 1;
            overflow: hidden;
        }

        .active::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: currentColor;
            opacity: 0.12;
            z-index: -1;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
    #authService = inject(AuthService);
    #dialog = inject(MatDialog);
    #runtimeConfigService = inject(RuntimeConfigService);

    links = signal<MenuItem[]>([{ path: 'trips', label: 'Courses', iconName: 'directions_bus' }]);
    debugDialog = viewChild('debugDialog');
    debugInfo = computed(() => {
        const config = this.#runtimeConfigService.config();
        return { environment, versionEnv, config, FeatureSettings };
    });

    #pressTimer?: ReturnType<typeof setTimeout>;
    #pressDurationInMs = 3000;

    constructor() {
        if (FeatureSettings['features.reservations']) {
            this.links.update(() => [...this.links(), { path: '/home/reservations', label: 'Réservations', iconName: 'pan_tool' }]);
        }
        if (FeatureSettings['features.connections']) {
            this.links.update(() => [...this.links(), { path: '/home/connections', label: 'Correspondances', iconName: 'alt_route' }]);
        }
        if (this.#authService.hasParametersPageAccess()) {
            this.links.update(() => [...this.links(), { path: '/home/parameters', label: 'Paramètres', iconName: 'settings' }]);
        }
    }

    startPress(event: MouseEvent | TouchEvent): void {
        event.preventDefault(); // Prévenir le comportement par défaut comme le scrolling
        this.#pressTimer = setTimeout(() => this.longPressActivated(), this.#pressDurationInMs);
    }

    cancelPress(): void {
        clearTimeout(this.#pressTimer); // Annuler le compteur si le curseur ou le doigt quitte l'élément
    }

    longPressActivated(): void {
        this.#showDebugDialog();
    }

    #showDebugDialog(): void {
        this.#dialog.open(this.debugDialog()! as TemplateRef<unknown>, {
            autoFocus: false,
        });
    }
}
