import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserStorageService } from '@features/login/user-storage.service';

export const sidHeaderInterceptor: HttpInterceptorFn = (req, next) => {
    const user = inject(UserStorageService).getUser();
    if (user && user.sid) {
        const url = new URL(req.url, window.location.origin);
        url.searchParams.set('sid', user.sid);
        const authReq = req.clone({
            url: url.toString(),
        });
        return next(authReq);
    }
    return next(req);
};
