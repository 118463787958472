import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { TripDetailsDto } from '@models/trip.model';
import { Observable, of } from 'rxjs';
import { TripService } from './trip.service';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeatureSettings } from '@features-settings';

export const tripByIdResolver: ResolveFn<Observable<TripDetailsDto | undefined>> = (route) => {
    const snackBar = inject(MatSnackBar);
    const router = inject(Router);
    const tripService = inject(TripService);

    const id = route.paramMap.get('id');
    const operationDateString = route.queryParamMap.get('operationDate');

    if (!id || !operationDateString) {
        snackBar.open(`ID de course ou date d'opération manquante`, 'Fermer');
        void router.navigate(['/home']);
        return of(undefined);
    }

    const operationDate = new Date(operationDateString);
    if (isNaN(operationDate.getTime())) {
        snackBar.open(`Format de date d'opération invalide`, 'Fermer');
        void router.navigate(['/home']);
        return of(undefined);
    }

    const includeLinkedTrips = FeatureSettings['features.trip.details.stopTable.displayLinkedTrips'];
    return tripService.getTripDetail$(id, operationDate, includeLinkedTrips).pipe(
        catchError((error) => {
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access */
            snackBar.open(error.message, 'Fermer');
            void router.navigate(['/home']);
            return of(undefined);
        }),
    );
};
