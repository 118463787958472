import { computed, Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    #activeRequestsCount = signal(0);
    loading = computed(() => this.#activeRequestsCount() > 0);

    onRequestStarting(): void {
        this.#activeRequestsCount.update((n) => n + 1);
    }

    onRequestEnded(): void {
        this.#activeRequestsCount.update((n) => Math.max(n - 1, 0));
    }
}
