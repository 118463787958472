import { Component, computed, inject, signal, TemplateRef, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { UserStorageService } from '@features/login/user-storage.service';
import { AuthService } from '@features/login/auth.service';
import { JsonPipe } from '@angular/common';
import { MatDialog, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { RuntimeConfigService } from '@shared/services/runtime-config.service';
import { environment } from '@environment';
import { versionEnv } from '@version';
import { FeatureSettings } from '@features-settings';

@Component({
    selector: 'app-user-menu',
    standalone: true,
    imports: [MatIconModule, MatMenuModule, MatButtonModule, JsonPipe, MatDialogContent, MatDialogTitle],
    template: `
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
            <span>{{ username() }}</span>
        </button>
        <mat-menu #menu="matMenu">
            <!--            <button mat-menu-item (click)="showDebugDialog()">-->
            <!--                <span class="flex justify-between items-center w-full">-->
            <!--                    <span>A propos</span>-->
            <!--                    <mat-icon class="ml-2">info</mat-icon>-->
            <!--                </span>-->
            <!--            </button>-->
            <button mat-menu-item (click)="disconnect()">
                <span class="flex justify-between items-center w-full">
                    <span>Déconnexion</span>
                    <mat-icon class="ml-2">exit_to_app</mat-icon>
                </span>
            </button>
        </mat-menu>

        <ng-template #debugDialog>
            <div mat-dialog-title>Informations de debug</div>
            <mat-dialog-content>
                <pre>{{ debugInfo() | json }}</pre>
            </mat-dialog-content>
        </ng-template>
    `,
    styles: '',
})
export class UserMenuComponent {
    username = signal(inject(UserStorageService).getUser()?.username);
    #authService = inject(AuthService);
    #router = inject(Router);
    #dialog = inject(MatDialog);
    #runtimeConfigService = inject(RuntimeConfigService);

    async disconnect(): Promise<void> {
        this.#authService.logout();
        await this.#router.navigate(['/login']);
    }

    debugDialog = viewChild('debugDialog');
    debugInfo = computed(() => {
        const config = this.#runtimeConfigService.config();
        return { environment, versionEnv, config, FeatureSettings };
    });

    showDebugDialog(): void {
        this.#dialog.open(this.debugDialog()! as TemplateRef<unknown>, {
            autoFocus: false,
        });
    }
}
